import Vue from 'vue'
import VueRouter from 'vue-router'


// client
import clienthome from '../pages/client/home.vue'
//////////////////////////////////////////////////////////////////////////////////////////////////////////////






// dashboard
import dashboardhome from '../pages/dashboard/home.vue'
import dashboardauth_login from '@/pages/dashboard/auth/login.vue'
import dashboardauth_forget from '@/pages/dashboard/auth/forget.vue'
import dashboardauth_reset from '@/pages/dashboard/auth/reset.vue'

import dashboardprofile from '@/pages/dashboard/profile.vue'


import dashboardusers from '@/pages/dashboard/account/users.vue'
import dashboardroles from '@/pages/dashboard/account/roles.vue'
import dashboardroles_profile from '@/pages/dashboard/account/roles_profile.vue'


import system_settings from '../pages/dashboard/system/settings.vue'
import system_menus from '../pages/dashboard/system/menus.vue'
import system_pages from '../pages/dashboard/system/pages.vue'
import system_permissions from '../pages/dashboard/system/permissions.vue'

import dashboardbuy_companies from '@/pages/dashboard/buy/companies.vue'
import dashboardbuy_companyinvoices from '@/pages/dashboard/buy/companyinvoices.vue'
import dashboardbuy_companyinvoicesdetail from '@/pages/dashboard/buy/companyinvoicesdetail.vue'
import dashboardbuy_companyprofile from '@/pages/dashboard/buy/companyprofile.vue'


import dashboardsale_customers from '@/pages/dashboard/sale/customers.vue'
import dashboardsale_customerreturndebtprint from '@/pages/dashboard/sale/printdebt.vue'
import dashboardsale_customerinvoices from '@/pages/dashboard/sale/customerinvoices.vue'
import dashboardsale_customerinvoicesdetail from '@/pages/dashboard/sale/customerinvoicesdetail.vue'
import dashboardsale_customerprofile from '@/pages/dashboard/sale/customerprofile.vue'
import dashboardsale_customerinvoicesprint from '@/pages/dashboard/sale/print.vue'


import dashboardproduct_productcategories from '@/pages/dashboard/product/productcategories.vue'
import dashboardproduct_brands from '@/pages/dashboard/product/brands.vue'
import dashboardproduct_products from '@/pages/dashboard/product/products.vue'
import dashboardproduct_productprofile from '@/pages/dashboard/product/productprofile.vue'



import dashboardexpense_expensecategories from '@/pages/dashboard/expense/expensecategories.vue'
import dashboardexpense_expenses from '@/pages/dashboard/expense/expenses.vue'


import dashboardsubs_cities from '@/pages/dashboard/subs/cities.vue'
import dashboardsubs_stores from '@/pages/dashboard/subs/stores.vue'
import dashboardsubs_units from '@/pages/dashboard/subs/units.vue'
import dashboardsubs_invoicetypes from '@/pages/dashboard/subs/invoicetypes.vue'
import dashboardsubs_currencies from '@/pages/dashboard/subs/currencies.vue'



import dashboardaccounting_generalreport from '@/pages/dashboard/accounting/generalreport.vue'


import notFound from '@/pages/404.vue'
Vue.use(VueRouter)



const routes = [
  {
    path: '*',
    name: 'notFound',
    component: notFound,
    meta: {
      hideNavbar: true,
    }
  },











  // dashboard
  {
    path: '/dashboard/',
    name: 'DashboardRootHome',
    component: dashboardhome,
    redirect: '/dashboard/home',
    meta: {
      hideNavbar: true,
    }
  },
  {
    path: '/dashboard/home',
    name: 'DashboardHome',
    component: dashboardhome,
    meta: {
      hideNavbar: true,
    }
  },
  {
    path: '/dashboard/auth/login',
    name: 'DashboardAuthLogin',
    component: dashboardauth_login,
    meta: {
      hideNavbar: true,
    }
  },
  {
    path: '/dashboard/auth/forget',
    name: 'DashboardAuthForget',
    component: dashboardauth_forget,
    meta: {
      hideNavbar: true,
    }
  },
  {
    path: '/dashboard/auth/reset',
    name: 'DashboardAuthReset',
    component: dashboardauth_reset,
    meta: {
      hideNavbar: true,
    }
  },
  {
    path: '/dashboard/profile',
    name: 'DashboardProfile',
    component: dashboardprofile,
    meta: {
      hideNavbar: true,
    }
  },






  {
    path: '/dashboard/account/users',
    name: 'DashboardUsers',
    component: dashboardusers,
    meta: {
      hideNavbar: true,
    }
  },
  {
    path: '/dashboard/account/roles',
    name: 'DashboardRoles',
    component: dashboardroles,
    meta: {
      hideNavbar: true,
    }
  },
  {
    path: '/dashboard/account/roles/:id',
    name: 'DashboardRolesProfile',
    component: dashboardroles_profile,
    meta: {
      hideNavbar: true,
    }
  },







  {
    path: '/dashboard/buy/companies',
    name: 'DashboardBuyCompanies',
    component: dashboardbuy_companies,
    meta: {
      hideNavbar: true,
    }
  },
  {
    path: '/dashboard/buy/companyinvoices',
    name: 'DashboardBuyCompanyInvoices',
    component: dashboardbuy_companyinvoices,
    meta: {
      hideNavbar: true,
    }
  },
  {
    path: '/dashboard/buy/companyinvoices/:id',
    name: 'DashboardBuyCompanyInvoicesDetail',
    component: dashboardbuy_companyinvoicesdetail,
    meta: {
      hideNavbar: true,
    }
  },
  {
    path: '/dashboard/buy/companies/:id',
    name: 'DashboardBuyCompanyProfile',
    component: dashboardbuy_companyprofile,
    meta: {
      hideNavbar: true,
    }
  },



  {
    path: '/dashboard/sale/customers',
    name: 'DashboardSaleCustomers',
    component: dashboardsale_customers,
    meta: {
      hideNavbar: true,
    }
  },
  {
    path: '/dashboard/sale/customerinvoices',
    name: 'DashboardSaleCustomerInvoices',
    component: dashboardsale_customerinvoices,
    meta: {
      hideNavbar: true,
    }
  },
  {
    path: '/dashboard/sale/customerinvoices/:id',
    name: 'DashboardSaleCustomerInvoicesDetail',
    component: dashboardsale_customerinvoicesdetail,
    meta: {
      hideNavbar: true,
    }
  },
  {
    path: '/dashboard/sale/customers/:id',
    name: 'DashboardSaleCustomerProfile',
    component: dashboardsale_customerprofile,
    meta: {
      hideNavbar: true,
    }
  },




  {
    path: '/dashboard/system/settings',
    name: 'DashboardSystemSettings',
    component: system_settings,
    meta: {
      hideNavbar: true,
    }
  },

  {
    path: '/dashboard/system/menus',
    name: 'DashboardSystemMenus',
    component: system_menus,
    meta: {
      hideNavbar: true,
    }
  },

  {
    path: '/dashboard/system/pages',
    name: 'DashboardSystemPages',
    component: system_pages,
    meta: {
      hideNavbar: true,
    }
  },

  {
    path: '/dashboard/system/permissions',
    name: 'DashboardSystemPermissions',
    component: system_permissions,
    meta: {
      hideNavbar: true,
    }
  },

  {
    path: '/dashboard/subs/cities',
    name: 'DashboardSubsCities',
    component: dashboardsubs_cities,
    meta: {
      hideNavbar: true,
    }
  },

  {
    path: '/dashboard/subs/stores',
    name: 'DashboardSubsStores',
    component: dashboardsubs_stores,
    meta: {
      hideNavbar: true,
    }
  },
  {
    path: '/dashboard/subs/units',
    name: 'DashboardSubsUnits',
    component: dashboardsubs_units,
    meta: {
      hideNavbar: true,
    }
  },
  {
    path: '/dashboard/product/productcategories',
    name: 'DashboardProductProductCategories',
    component: dashboardproduct_productcategories,
    meta: {
      hideNavbar: true,
    }
  },
  {
    path: '/dashboard/product/brands',
    name: 'DashboardProductBrands',
    component: dashboardproduct_brands,
    meta: {
      hideNavbar: true,
    }
  },
  {
    path: '/dashboard/product/products',
    name: 'DashboardProductProducts',
    component: dashboardproduct_products,
    meta: {
      hideNavbar: true,
    }
  },
  {
    path: '/dashboard/product/products/:id',
    name: 'DashboardProductProductProfile',
    component: dashboardproduct_productprofile,
    meta: {
      hideNavbar: true,
    }
  },

  {
    path: '/dashboard/expense/expensecategories',
    name: 'DashboardExpenseExpenseCategories',
    component: dashboardexpense_expensecategories,
    meta: {
      hideNavbar: true,
    }
  },
  {
    path: '/dashboard/expense/expenses',
    name: 'DashboardExpenseExpenses',
    component: dashboardexpense_expenses,
    meta: {
      hideNavbar: true,
    }
  },




  {
    path: '/dashboard/subs/invoicetypes',
    name: 'DashboardSubsInvoiceTypes',
    component: dashboardsubs_invoicetypes,
    meta: {
      hideNavbar: true,
    }
  },
  {
    path: '/dashboard/subs/currencies',
    name: 'DashboardSubsCurrencies',
    component: dashboardsubs_currencies,
    meta: {
      hideNavbar: true,
    }
  },





  {
    path: '/dashboard/accounting/generalreport',
    name: 'AccountingReport',
    component: dashboardaccounting_generalreport,
    meta: {
      hideNavbar: true,
    }
  },



  // client
  {
    path: '/',
    name: 'ClientHome',
    component: clienthome
  },




  {
    path: '/dashboard/sale/customerinvoices/print/:id',
    name: 'DashboardSaleCustomerInvoicesPrint',
    component: dashboardsale_customerinvoicesprint,
    meta: {
      hideNavbar: true,
    }
  },


  {
    path: '/dashboard/sale/customers/printreturndebt/:cid/:did',
    name: 'DashboardSaleCustomerReturnDebtPrint',
    component: dashboardsale_customerreturndebtprint,
    meta: {
      hideNavbar: true,
    }
  },





]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
