<template>
  <div>
    <v-container fluid class="mb-4">
      <v-row class="text-center">
        <v-col cols="12 text-start mx-auto notprintable" sm="11">
          <v-btn @click="$router.go(-1)" :style="lang() === 'en' ? 'float:right' : 'float:left'"
            class="mb-2 mr-2 h-100 radius-5 nzar-font white--text bg-main">
            <v-icon v-if="lang() === 'ku'"> mdi-arrow-left</v-icon>
            <v-icon v-if="lang() === 'ar'"> mdi-arrow-left</v-icon>
            <v-icon v-if="lang() === 'en'"> mdi-arrow-right</v-icon>
            {{ langkeyword("BACK") }}
          </v-btn>
          <v-btn onclick="window.print()" :style="lang() === 'en' ? 'float:left' : 'float:right'"
            class="mb-2 mr-2 h-100 radius-5 nzar-font white--text primary" x-large>
            <v-icon> mdi-printer</v-icon>
            {{ langkeyword("BACK") }}
          </v-btn>
        </v-col>

        <v-col cols="12 text-start mx-auto" sm="12" class="printbg white rounded rounded">
          <v-card color="transparent" elevation="0" outlined shaped>
            <v-row>
              <div class="col-8 col-sm-8 col-md-8 col-lg-10 col-xl-10 pa-4 m-auto text-justify">
                <v-card-title class="mx-auto">
                  <h2 class="dark--text" style="font-size: 3rem" v-if="lang() === 'ku'">
                    {{ $store.state.setting.ku_name }}
                  </h2>
                  <h2 class="dark--text" style="font-size: 3rem" v-if="lang() === 'ar'">
                    {{ $store.state.setting.ar_name }}
                  </h2>
                  <h2 class="dark--text" style="font-size: 3rem" v-if="lang() === 'en'">
                    {{ $store.state.setting.en_name }}
                  </h2>
                </v-card-title>
                <br />
                <v-card-text>
                  <h3 class="dark--text">
                    {{ langkeyword("PHONE") }} :
                    {{ $store.state.setting.phone1 }}
                    -
                    {{ $store.state.setting.phone2 }}
                  </h3>
                  <br />
                  <h3 class="dark--text">
                    {{ langkeyword("EMAIL") }} :
                    {{ $store.state.setting.email }}
                  </h3>
                  <br />
                  <h3 class="dark--text">
                    {{ langkeyword("ADDRESS") }} :
                    {{ $store.state.setting.address }}
                  </h3>
                  <br />
                  <h3 class="dark--text">
                    {{ langkeyword("LICENSENUMBER") }} :
                    {{ $store.state.setting.licensenumber }}
                  </h3>
                  <br />
                  <h3 class="dark--text">
                    {{ langkeyword("WEBSITE") }} :
                    {{ $store.state.setting.website }}
                  </h3>
                  <br />

                </v-card-text>
              </div>

              <div class="col-4 col-sm-4 col-md-4 col-lg-2 col-xl-2 ma-auto m-auto text-end">
                <img class="text-start logo" :src="uploadPath(this.$store.state.setting.logo)
                  " alt="logo" style="width: 100%" />
                <hr>
                <h3 class="pa-3">{{ langkeyword("DATE") }} : {{ customerReturnDebtDetail("date") }}</h3>
              </div>
            </v-row>
            <v-row>
              <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 text-center">
                <h3>{{ langkeyword("CUSTOMER") }} : {{ customerReturnDebtDetail("customer") }}</h3>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 text-center">
                <h3>{{ langkeyword("PHONE") }} : {{ customerReturnDebtDetail("phone") }}</h3>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 text-center">
                <h3>{{ langkeyword("ADDRESS") }} : {{ customerReturnDebtDetail("address") }}</h3>
              </div>
            </v-row>
            <hr />
            <v-row>
              <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pa-4 ma-auto text-center">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-center " style="font-size:1.4rem;">
                          {{ langkeyword("AMOUNT") }}
                        </th>
                        <th class="" style="font-size:1.4rem;">
                          {{ customerReturnDebtDetail("currency_id") === 1 ? dinar(customerReturnDebtDetail('amount')) :
                            dollar(customerReturnDebtDetail('amount')) }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th class="text-center" style="font-size:1.4rem;">{{ langkeyword("IQDDEBT") }}</th>
                        <th style="font-size:1.4rem;">{{ dinar(customerinfo.iqddebt) }}</th>
                      </tr>
                      <tr>
                        <th class="text-center" style="font-size:1.4rem;">{{ langkeyword("USDDEBT") }}</th>
                        <th style="font-size:1.4rem;">{{ dollar(customerinfo.usddebt) }}</th>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
            </v-row>

            <v-card-body> </v-card-body>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <br /><br /><br />
    <dashboardFooter />
  </div>
</template>

<script>
import CustomerRequests from "../../../requests/dashboard/debt/Customers";
export default {
  data() {
    return {
      customerinfo: {},
    };
  },
  watch: {
    /*
          filter: (value) => {
            return value == this.$route.params.id;
          },
*/

    options: {
      handler() {
        this.readData();
      },
    },
  },
  mounted() {
    this.readData();
  },
  computed: {
  },
  methods: {
    customerReturnDebtDetail(field) {
      let actField = "";
      if (field === "currency") {
        if (this.langoptions("value") === "ku") {
          actField =
            this.$store.state.currentcustomerinvoicedetail.currencies_ku_name;
        } else if (this.langoptions("value") === "ar") {
          actField =
            this.$store.state.currentcustomerinvoicedetail.currencies_ar_name;
        } else if (this.langoptions("value") === "en") {
          actField =
            this.$store.state.currentcustomerinvoicedetail.currencies_en_name;
        }
      } else if (field === "date") {
        actField = this.$store.state.currentcustomerinvoicedetail.date;
      } else if (field === "symbol") {
        actField =
          this.$store.state.currentcustomerinvoicedetail.currencies_symbol;
      } else if (field === "invoicenumber") {
        actField = this.$store.state.currentcustomerinvoicedetail.invoicenumber;
      } else if (field === "customer") {
        actField =
          this.$store.state.currentcustomerinvoicedetail.customers_name;
      } else if (field === "phone") {
        actField =
          this.$store.state.currentcustomerinvoicedetail.customers_phone;
      } else if (field === "address") {
        actField =
          this.$store.state.currentcustomerinvoicedetail.customers_address;
      } else if (field === "customer_id") {
        actField = this.$store.state.currentcustomerinvoicedetail.customer_id;
      } else if (field === "currency_id") {
        actField = this.$store.state.currentcustomerinvoicedetail.currency_id;
      } else if (field === "amount") {
        actField = this.$store.state.currentcustomerinvoicedetail.amount;
      }
      return actField;
    },
    readData() {
      CustomerRequests.getOne(
        {
          getOneCustomers: true,
          customer_id: this.$route.params.cid,
        },
        (res) => {
          if (res.data.status === false) {
            this.error_msg = this.langkeyword(res.data.msg);
            setTimeout(() => {
              this.$router.push("/*");
            }, 1000);
          } else if (res.data.status === true) {
            this.error_msg = null;
            this.customerinfo = res.data.data;
          }
          this.cleanMessages();
        }
      ),
        CustomerRequests.getOneReturnDebt(
          {
            getOneCustomerReturnDebt: true,
            customerreturndebt_id: this.$route.params.did,
          },
          (res) => {
            if (res.data.status === false) {
              this.error_msg = this.langkeyword(res.data.msg);
              setTimeout(() => {
                this.$router.push("/*");
              }, 1000);
            } else if (res.data.status === true) {
              this.error_msg = null;
              this.$store.state.currentcustomerinvoicedetail = res.data.data;
            }
          }
        )
    },
  },
};
</script>
<style scoped>
.container,
.container-fluid {
  background: white;
}

@media print {
  .notprintable {
    display: none;
  }
}

.printbg {
  color: black;
}
</style>