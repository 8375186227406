import axios from 'axios'


export default {

    async getAll(params, callback) {
        return await axios.post("/app/dashboard/sale/customerinvoiceitems/getall", params)
            .then(res => {
                callback(res)
            })
            .catch(err => {
                console.log('response error ' + err)
            })
    },


    async add(params, callback) {
        return await axios.post("/app/dashboard/sale/customerinvoiceitems/add", params)
            .then(res => {
                callback(res)
            })
            .catch(err => {
                console.log('response error ' + err)
            })
    },


    async edit(params, callback) {
        return await axios.post("/app/dashboard/sale/customerinvoiceitems/edit", params)
            .then(res => {
                callback(res)
            })
            .catch(err => {
                console.log('response error ' + err)
            })
    },

    async delete(params, callback) {
        return await axios.post("/app/dashboard/sale/customerinvoiceitems/delete", params)
            .then(res => {
                callback(res)
            })
            .catch(err => {
                console.log('response error ' + err)
            })
    },


    async getOne(params, callback) {
        return await axios.post("/app/dashboard/sale/customerinvoiceitems/getone", params)
            .then(res => {
                callback(res)
            })
            .catch(err => {
                console.log('response error ' + err)
            })
    },




}

