import axios from 'axios'


export default {


    async getAllReturnDebt(params, callback) {
        return await axios.post("/app/dashboard/debt/customers/getallreturndebt", params)
            .then(res => {
                callback(res)
            })
            .catch(err => {
                console.log('response error ' + err)
            })
    },



    async getOne(params, callback) {
        return await axios.post("/app/dashboard/debt/customers/getone", params)
            .then(res => {
                callback(res)
            })
            .catch(err => {
                console.log('response error ' + err)
            })
    },



    async returnDebt(params, callback) {
        return await axios.post("/app/dashboard/debt/customers/returndebt", params)
            .then(res => {
                callback(res)
            })
            .catch(err => {
                console.log('response error ' + err)
            })
    },



    async deleteReturnedDebt(params, callback) {
        return await axios.post("/app/dashboard/debt/customers/deletereturneddebt", params)
            .then(res => {
                callback(res)
            })
            .catch(err => {
                console.log('response error ' + err)
            })
    },




    async getOneReturnDebt(params, callback) {
        return await axios.post("/app/dashboard/debt/customers/getonereturndebt", params)
            .then(res => {
                callback(res)
            })
            .catch(err => {
                console.log('response error ' + err)
            })
    },

}

